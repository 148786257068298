import mediaTrust from "./mediatrust";
import polar from "./polar";
import permutive from "./permutive";
import testing from "./testing";
import piano from "./piano";
import { logMeasurement } from "../perf";
import pubwise from "./pubwise";
import upsapi from "./upsapi";
import refresh from "./refresh";

// Plugins are a way to enscapulate vendor
// functionality or other single-purpose code in one place.
// They can run instantly (e.g., set up key/values automatically)
// or return a promise that needs awaiting (e.g., loading a vendor).

const PERF_KEYS = {
  pluginsStart: "ads:plugins_start",
  pluginsEnd: "ads:plugins_ready",
  measurePlugins: "ads:measure_plugins",
};

export async function runPlugins() {
  performance.mark(PERF_KEYS.pluginsStart);
  await Promise.allSettled([
    upsapi(),
    testing(),
    piano(),
    mediaTrust(),
    polar(),
    permutive(),
    pubwise(),
    refresh(),
  ]);
  performance.mark(PERF_KEYS.pluginsEnd);
  performance.measure(PERF_KEYS.measurePlugins, PERF_KEYS.pluginsStart, PERF_KEYS.pluginsEnd);
  logMeasurement(PERF_KEYS.measurePlugins, "Ads: plugins complete in");
}
